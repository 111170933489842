import { render, staticRenderFns } from "./knowledgebase.vue?vue&type=template&id=1029fd2c&lang=true&"
import script from "./knowledgebase.vue?vue&type=script&lang=js&"
export * from "./knowledgebase.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports